import React from "react";
import Results from "../containers/Results";
import CTASection from "../containers/CTASection";
import MainLayout from "../components/MainLayout";

export default () => {
  return (
    <MainLayout hasGradient={false} title="Features" pathname="/features">
      <Results />
      {/* <CTASection
        title="Feedback-Driven Design"
        description="Validate your design assumption by bringing VisualEyes technology to your daily design process."
        cta="Create your Account"
      /> */}
    </MainLayout>
  );
};
